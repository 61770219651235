import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { useLangContext } from '../context/lang.context'
import Tiles from '../components/Tiles'
import Headline from '../components/Headline'
import Section from '../components/Section'

const content = {
  title: {
    pl: 'Poznaj najlepszą w Polsce logistykę dla branż',
    en: 'Discover the best logistics in Poland for industries',
  },
  desc: {
    pl: `
    Omida VLS stawia na doświadczenie. Dzięki obsłudze logistycznej firm z różnych branży jesteśmy w stanie zorganizować dla Ciebie każdy transport. Pozwól swojemu Biznesowi rozwinąć się razem z nami.
    `,
    en: `
    Omida VLS focuses on experience. Thanks to logistics services for companies from various industries, we are able to organize any transport for you. Let your business grow with us.
    `,
  },
}

const IndexIndustries = ({ include, title, desc }) => {
  const { lang } = useLangContext()
  const { children } = useStaticQuery(graphql`
    {
      children: allContentfulTransportBranza(
        sort: { fields: name }
        filter: { higherorder: { eq: true } }
      ) {
        nodes {
          name
          industryName
          slug
          node_locale
          image {
            gatsbyImageData(width: 480, quality: 100, formats: [JPG])
          }
        }
      }
    }
  `)

  const links = children.nodes
    .filter((node) => node.node_locale === 'pl')
    .filter(({ industryName }) =>
      include ? include.includes(industryName) : true
    ) // skip if include not provided
    .map((node) => ({
      slug: node.slug,
      name: node.industryName,
      image: node.image,
      subtitle: lang === 'pl' ? 'Branża' : 'Industry',
    }))

  return (
    <>
      <Section top>
        <Headline
          h={2}
          title={title || content.title[lang]}
          desc={desc || content.desc[lang]}
          large
          gradient
        />
      </Section>
      <Tiles data={links} marginMinimal small subtitleOnTop />
    </>
  )
}

export default IndexIndustries
